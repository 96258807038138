import React from 'react';

const VideoPlayer = ({ videoUrl, posterUrl }) => {
    return (
        <div style={{ position: 'relative', width: '100%', height: '400px' }}>
            <video
                src={videoUrl}
                controls
                controlsList="nodownload" // Remove opção de download nos controles
                poster={posterUrl} // Definindo o poster com a URL
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                onContextMenu={(e) => e.preventDefault()} // Impede menu de contexto
            >
                Seu navegador não suporta o elemento de vídeo.
            </video>
        </div>
    );
};

export default VideoPlayer;
