import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, TextField, CircularProgress, Box, Button } from '@mui/material';
import MovieCard from '../components/MovieCard';
import axios from 'axios';
import WhatsAppButton from '../components/WhatsAppButton';
import { usePageConfig } from '../App';

import { Telegram } from '@mui/icons-material';
const MovieList = () => {
    const [movies, setMovies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const pageConfig = usePageConfig();
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');

    const fetchMovies = async (page, query = '') => {
        try {
            setLoading(true);
            const response = await axios.get(`videos`, {
                params: { page, limit: 10, name: query },
            });

            const { videos, totalPages } = response.data;

            if (page === 1) {
                setMovies(videos);
            } else {
                setMovies(prevMovies => [...prevMovies, ...videos]);
            }

            setHasMore(page < totalPages);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const handler = setTimeout(() => setDebouncedQuery(searchQuery), 1000);
        return () => clearTimeout(handler);
    }, [searchQuery]);
    useEffect(() => {
        fetchMovies(page, debouncedQuery);
    }, [page, debouncedQuery]);
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
                if (!loading && hasMore) {
                    setPage(prevPage => prevPage + 1);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    useEffect(() => {
        setPage(1);
        setHasMore(true);
    }, [debouncedQuery]);

    return (
        <Container
            sx={{
                position: 'relative',
                minHeight: '100vh',
                padding: 4,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    zIndex: 10,
                }}
            >
                <WhatsAppButton phoneNumber={pageConfig.phoneNumber} message={"Olá, gostaria de fazer um pedido!"} />
            </Box>
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundImage: 'url("https://m.media-amazon.com/images/I/91EoDZZxvcL._AC_UF894,1000_QL80_.jpg")',
                    backgroundPosition: 'center',
                    opacity: 0.7,
                    zIndex: -1,
                }}
            /><div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: '#000',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '250px',
                    width: '100%',
                    padding: '20px 0',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
                }}
            >
                <h1
                    style={{
                        fontFamily: "'Arial', sans-serif",
                        fontSize: '36px',
                        fontWeight: 'bold',
                        color: '#e50914',
                        textTransform: 'uppercase',
                        letterSpacing: '4px',
                        margin: '0 0 10px',
                    }}
                >
                    ARTEFLIX
                </h1>

                <Typography
                    variant="body1"
                    style={{
                        textAlign: 'center',
                        color: '#FFFFFF',
                        fontSize: '18px',
                        marginBottom: '15px',
                    }}
                >
                    Mais de Mil Minisséries, Doramas, Filmes Completos para você assistir
                </Typography>

                <TextField
                    variant="outlined"
                    placeholder="Pesquisar..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{
                        backgroundColor: '#FFFFFF',
                        width: '90%',
                        borderRadius: '4px',
                    }}
                />
                <Button
                    variant="contained"
                    href="https://t.me/arteflix"
                    target="_blank"
                    sx={{
                        marginTop: '10px',
                        backgroundColor: '#e50914',
                        color: '#FFFFFF',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        '&:hover': {
                            backgroundColor: '#b20710',
                        },
                    }}
                >
                    <b>
                        Clique aqui
                        <Telegram style={{ marginRight: '5px', color: '#0088cc', fontSize: '22px' }} />
                        Grupo Telegram Todas as Minisséries
                    </b>
                </Button>

            </div>

            {error ? (
                <Typography variant="h6" sx={{ textAlign: 'center', color: '#FF4081', mt: 4 }}>
                    Erro: {error}
                </Typography>
            ) : loading && movies.length === 0 ? (
                // Spinner centralizado enquanto carrega a lista inicial
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 'calc(100vh - 200px)',
                        zIndex: 10,
                    }}
                >
                    <CircularProgress color="secondary" size={80} />
                </Box>
            ) : (
                <Grid container style={{ marginTop: "250px" }} spacing={2}>
                    {!loading && movies.length === 0 ? (
                        <Box
                            sx={{
                                zIndex: 10,
                            }}
                        >
                            <WhatsAppButton phoneNumber={pageConfig.phoneNumber} message={"Olá, gostaria de fazer um pedido!"} type='button' />
                        </Box>
                    ) : movies.map(movie => (
                        <Grid item xs={12} sm={6} md={4} key={movie.id}>
                            <MovieCard movie={movie} />
                        </Grid>
                    ))}
                </Grid>
            )}
            {loading && movies.length > 0 && (
                // Spinner ao carregar mais filmes no scroll infinito
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 4,
                        zIndex: 10,
                    }}
                >
                    <CircularProgress color="secondary" size={60} />
                </Box>
            )}
        </Container>
    );
};

export default MovieList;
