import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardMedia,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Movie } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import VideoPlayer from '../components/VideoEmbed';
import { usePageConfig } from '../App';


const FetchNumber = () => {
  const { number } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [telegram, setTelegram] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [name, setName] = useState('');
  const [qrCode, setQrCode] = useState('');
  const [userId, setUserId] = useState('');
  const [movieLink, setMovieLink] = useState(null); // Estado para controlar a exibição do filme
  const [websocket, setWebSocket] = useState(null);
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const pageConfig = usePageConfig();
  const urlBackend = pageConfig.urlBackend
  const urlWebsocket = 'wss://dramas.shop'

  useEffect(() => {

    let existingUserId = localStorage.getItem('userId');
    if (existingUserId) {
      setUserId(existingUserId);
    } else {
      const newUserId = `user_${Date.now()}`; // Generate a unique ID based on the current timestamp
      existingUserId = newUserId;
      localStorage.setItem('userId', newUserId);
      setUserId(newUserId);
    }

    const fetchData = async () => {
      if (number) {

        try {
          const response = await axios.post(`link`, { userId: existingUserId, linkId: number });
          setMovieLink(response.data);
        } catch (err) {
          console.log(err)

          const fetchVideosData = async () => {
            try {
              const response = await axios.get(`videos/${number}`);
              setData(response.data);
            } catch (err) {
              setError(err.message);
            }
          };

          fetchVideosData();
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [number]);

  const getPaymentResult = async () => {
    try {

      const response = await axios.post(`link`, { userId, linkId: number });

      setMovieLink(response.data);

    } catch (error) {
      alert('Ainda não identificamos o pagamento')
    }

  }

  const handleGeneratePix = async (video) => {
    try {
      const response = await axios.post(`gerarPix`, { number, video, telegram: whatsapp, whatsapp, name, userId });
      setQrCode(response.data)
      const ws = new WebSocket(`${urlWebsocket}/websocket`); // Insira a URL do seu servidor WebSocket

      ws.onopen = () => {
        console.log('Conexão WebSocket estabelecida');

        // Envia o userId após a conexão ser estabelecida
        const message = JSON.stringify({ type: 'register', userId });
        ws.send(message);
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log(message)
        if (message.type == 'payment') {
          const { data } = message;
          if (data.status == 'approved') {
            setMovieLink(data);
          } // Altera o estado quando o evento é recebido
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
      };

      ws.onclose = () => {
        console.log('Conexão WebSocket fechada');
      };

      setWebSocket(ws); // Armazena o WebSocket no estado


    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(qrCode.text)
    alert('Copiado com sucesso.')
  }

  const handleBackClick = () => {
    navigate('/'); // Redireciona para a tela raiz
  };

  if (loading) return <CircularProgress style={{ margin: '20px' }} />;
  if (error) return <Typography color="error">❌ Error: {error}</Typography>;

  return movieLink ? 
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        bgcolor: 'black',
        minHeight: '100vh',
        color: 'white',
        padding: 2, // Reduzindo o padding
        position: 'relative',
        overflow: 'hidden', // Impede o conteúdo de transbordar
      }}
    >
      {/* Ícone de voltar */}
      <ArrowBackIosIcon
        onClick={handleBackClick}
        sx={{
          fontSize: 30, // Diminuindo o tamanho do ícone
          color: 'white',
          position: 'absolute',
          top: 20,
          left: 20,
          cursor: 'pointer', // Adiciona o efeito de cursor pointer
        }}
      />

      {/* Título do vídeo */}
      <Typography
        variant="h6"
        sx={{
          mb: 1, // Reduzindo a margem inferior
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1rem', // Diminuindo o tamanho da fonte
        }}
      >
        {movieLink.video.name.replace(/\.[^/.]+$/, '')}
      </Typography>

      {/* Descrição do vídeo */}
      <Typography
        variant="body2" // Usando o tipo 'body2' para o texto
        sx={{
          mb: 2, // Reduzindo a margem inferior
          textAlign: 'center',
          maxWidth: 500, // Limitando a largura da descrição
          lineHeight: 1.4, // Aumentando o espaçamento entre as linhas
          fontSize: '0.9rem', // Ajustando o tamanho da fonte
        }}
      >
        {movieLink.video.description}
      </Typography>

      {/* Lista de vídeos com poster */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2, // Espaçamento entre os vídeos
          width: '100%',
          alignItems: 'center',
        }}
      >
        {movieLink.files.map((item) => (
          <div key={item.id} style={{ width: '100%' }}>
            {movieLink.files.length > 1 && <Typography>{item.description}</Typography>}
            <VideoPlayer
              videoUrl={item.link}
              posterUrl={`https://curtaflix.shop/telegram/images/${movieLink?.video?.image}`}
            />
          </div>
        ))}
      </Box>
    </Box>
    : qrCode ? (
      <Container maxWidth="sm" style={{ margin: '16px auto' }}>
        <Card elevation={5}>
          <Box style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 20, marginTop: 20 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setQrCode(null)}
              style={{ marginBottom: '16px' }}
            >
              Voltar
            </Button>
          </Box>
          <CardMedia
            component="img"
            height={isMobile ? 200 : 400}
            image={`https://curtaflix.shop/telegram/images/${data?.image}`}
            alt={data?.description}
            style={{ objectFit: 'contain' }} // Altera 'cover' para 'contain'
          />
          <CardContent>

            <Typography
              variant="subtitle1"
              color="textSecondary"
              style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}
            >

              <b><Movie style={{ marginRight: '8px', color: '#f39c12' }} />{data?.name.replace(/\.[^/.]+$/, "")}</b>
            </Typography>

            <Box style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 25, marginTop: 20 }}>
              <Button
                variant="contained"
                onClick={() => getPaymentResult()}
                style={{
                  backgroundColor: '#4CAF50', // Verde mais suave
                  color: '#FFFFFF', // Texto branco para contraste
                  marginBottom: '16px'
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: '#388E3C', // Um tom de verde mais escuro ao passar o mouse
                  }
                }}
              >
                Click após efetuar o pagamento
              </Button>
            </Box>

            <Box
              style={{
                border: '1px solid #ccc', // Borda cinza
                borderRadius: '4px', // Bordas arredondadas
                padding: '10px', // Espaçamento interno
                maxWidth: '300px', // Largura máxima (ajuste conforme necessário)
                cursor: 'pointer', // Indica que é interativo // Adiciona a funcionalidade de copiar
              }}
              onClick={() => handleCopy(qrCode.text)}
            >
              <Typography
                variant="caption" // Tamanho do texto menor
                color="textSecondary"
                style={{ whiteSpace: 'pre-line', wordBreak: 'break-word' }}
              >
                {qrCode.text}
              </Typography>
            </Box>


            <Box style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 25, marginTop: 20 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigator.clipboard.writeText(qrCode.text)}
                style={{ marginBottom: '16px' }}
              >
                Copiar Pix
              </Button>
            </Box>

            <CardMedia
              component="img"
              alt="Imagem gerada"
              image={`data:image/png;base64,${qrCode.base64}`}
              style={{ objectFit: 'contain', maxHeight: '200px', maxWidth: '100%', marginBottom: '16px', margin: '0 auto' }}
            />
          </CardContent>
        </Card>
      </Container>
    ) : (
      <Container maxWidth="sm" style={{ marginTop: '20px', padding: '0 16px', fontFamily: 'Arial, sans-serif' }}>
        <Card elevation={5} style={{ borderRadius: '15px', overflow: 'hidden' }}>
          <CardMedia
            component="img"
            height={isMobile ? 350 : 550}
            image={`https://curtaflix.shop/telegram/images/${data?.image}`}
            alt={data?.description}
            style={{ objectFit: 'contain' }} // Altera 'cover' para 'contain'
          />
          <Box style={{ display: 'flex', justifyContent: 'flex-start', position: 'relative', left: 20, top: isMobile ? -330 : -530 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBackClick}
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo translúcido
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)'

                },
                padding: 1
              }}
            >
              Voltar
            </Button>
          </Box>
          <CardContent>
            <Typography variant="h6" color="textSecondary" style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
              <b><Movie style={{ marginRight: '8px', color: '#f39c12' }} />{data?.name.replace(/\.[^/.]+$/, "")}</b>
            </Typography>
            {true ?
              <div>
                <Typography
                  variant="body1"
                  style={{
                    margin: '10px 0',
                    fontSize: isMobile ? '1rem' : '1.2rem',
                    color: '#555',
                    lineHeight: '1.5',
                    textAlign: 'center',
                  }}
                >
                  📢✨ <strong>Disponível agora no Telegram!</strong>
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    margin: '10px 0',
                    fontSize: isMobile ? '1rem' : '1.2rem',
                    color: '#555',
                    lineHeight: '1.5',
                    textAlign: 'center',
                  }}
                >
                  🎬 Clique no botão abaixo e comece a assistir agora mesmo! 👇
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                  <a
                    href={data?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo translúcido
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: 'rgba(255, 255, 255, 0.1)'
    
                        },
                        padding: '10px 20px',
                        borderRadius: '25px',
                        fontSize: isMobile ? '0.9rem' : '1rem',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      🎥 Assistir Agora
                    </Button>
                  </a>
                </div>
              </div>
              : <div>
                <Typography
                  variant="body1"
                  style={{
                    margin: '10px 0',
                    fontSize: isMobile ? '1rem' : '1.2rem',
                    color: '#555',
                    lineHeight: '1.5'
                  }}
                >
                  💬 Digite seu contato, temos brindes, novidades e novas séries e filmes!
                </Typography>
                <Box component="form" sx={{ '& > :not(style)': { marginBottom: 2 } }}>
                  <TextField
                    label="Nome (Opcional) 🤗"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    inputProps={{ style: { fontSize: isMobile ? '0.9rem' : '1rem' } }}
                    style={{ backgroundColor: '#f1f1f1', borderRadius: '8px' }}
                  />
                  <TextField
                    label="WhatsApp ou Telegram (Opcional) 📲"
                    variant="outlined"
                    fullWidth
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    inputProps={{ style: { fontSize: isMobile ? '0.9rem' : '1rem' } }}
                    style={{ backgroundColor: '#f1f1f1', borderRadius: '8px' }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => handleGeneratePix(data)}
                  style={{
                    marginTop: '10px',
                    fontSize: isMobile ? '1rem' : '1.1rem',
                    fontWeight: 'bold',
                    padding: isMobile ? '12px' : '14px',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fundo translúcido
                    color: '#FFFFFF',
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)'

                    },
                  }}
                >
                  ✨ Gerar Pix para Pagamento ✨
                </Button>
              </div>}
          </CardContent>
        </Card>
      </Container>
    );
};

export default FetchNumber;
